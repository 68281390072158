import { darken } from 'polished'
import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Text } from 'rebass'
import styled from 'styled-components'

import { TYPE } from '../../theme'
import { transparentize } from 'polished'
import { CardNoise } from '../../components/earn/styled'
import { ButtonEmpty } from '../../components/Button'

// import { useColor } from '../../hooks/useColor'

import Card, {  LightCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
// import CurrencyLogo from '../../components/CurrencyLogo'
// import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { RowBetween, RowFixed } from '../../components/Row'
// import { Dots } from '../../components/swap/styleds'
import LogoDark from '../../assets/images/zens.png'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
const StyledPositionCard = styled(LightCard)<{ bgColor: any }>`
  border: none;
  background: ${({ theme, bgColor }) =>
  `radial-gradient(91.85% 100% at 1.84% 0%, ${transparentize(0.8, bgColor)} 0%, ${theme.bg3} 100%) `};
  position: relative;
  overflow: hidden;
`

interface PositionCardProps {
  showUnwrapped?: boolean
  border?: string
}

export function MinimalPositionCard() {
  // const [showMore, setShowMore] = useState(false)
  return (
    <>
      <LightCard>
        <TYPE.subHeader style={{ textAlign: 'center' }}>
            <span role="img" aria-label="wizard-icon">
              ⭐️
            </span>{' '}
          By adding liquidity you&apos;ll earn 0.3% of all trades on this pair proportional to your share of the pool.
          Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
        </TYPE.subHeader>
      </LightCard>
    </>
  )
}

export default function FullPositionCard({ border }: PositionCardProps) {

  const [showMore, setShowMore] = useState(false)

  const backgroundColor = "#2172E5"

  return (
    <StyledPositionCard border={border} bgColor={backgroundColor}>
      <CardNoise />
      <AutoColumn gap="12px">
        <FixedHeightRow>
          <RowFixed>
            <img width={'24px'} src={LogoDark} alt="logo" />

            {/*<DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />*/}
            <Text fontWeight={500} fontSize={18}>
              Total Pot: 10,049 ZENS
            </Text>
          </RowFixed>

          <RowFixed gap="8px">
            <ButtonEmpty
              padding="6px 8px"
              borderRadius="12px"
              width="fit-content"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? (
                <>
                  {' '}
                  Hide
                  <ChevronUp size="18" style={{ marginLeft: '10px' }} />
                </>
              ) : (
                <>
                  Details
                  <ChevronDown size="18" style={{ marginLeft: '10px' }} />
                </>
              )}
            </ButtonEmpty>
          </RowFixed>
        </FixedHeightRow>

        {showMore && (
          <AutoColumn gap="8px">
            <FixedHeightRow>
              <Text fontSize={14} fontWeight={500}>
                No. Matched
              </Text>
              <Text fontSize={14} fontWeight={500}>
                Prize Pot
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={16} fontWeight={500}>
                  4
                </Text>
              </RowFixed>
              <Text fontSize={16} fontWeight={500}>
                7,034
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={12} fontWeight={500}>
                  3
                </Text>
              </RowFixed>
              <Text fontSize={12} fontWeight={500}>
                2,010
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={12} fontWeight={500}>
                  2
                </Text>
              </RowFixed>
              <Text fontSize={12} fontWeight={500}>
                234
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={14} fontWeight={500}>
                 To Fee:
                </Text>
              </RowFixed>
              <Text fontSize={14} fontWeight={500}>
                234
              </Text>
            </FixedHeightRow>
          </AutoColumn>
        )}
      </AutoColumn>
    </StyledPositionCard>
  )
}
