import React, { useContext } from 'react'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import styled, { ThemeContext } from 'styled-components'
import { TYPE, ExternalLink } from '../../theme'
import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
import FullPositionCard from './topinfo'
import { useActiveWeb3React } from '../../hooks'
import Card, { BlueCard } from '../../components/Card'
import { ButtonPrimary } from '../../components/Button'
// import { Dots } from '../../components/swap/styleds'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`



export default function Earn() {
  const DataRow = styled(RowBetween)`
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
  `
  const theme = useContext(ThemeContext)

  const { account } = useActiveWeb3React()

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <DataCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>ZENSSWAP Lottery</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  Buy tickets with ZENSTOKEN
                  Win if 2, 3, or 4 of your ticket numbers match!
                </TYPE.white>
              </RowBetween>{' '}
              <ExternalLink
                style={{ color: 'white', textDecoration: 'underline' }}
                href="https://github.com/zensdomains/WHITEPAPER"
                target="_blank"
              >
                <TYPE.white fontSize={14}>Read more about Lottery</TYPE.white>
              </ExternalLink>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </DataCard>
      </TopSection>

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        <DataRow style={{ alignItems: 'baseline' }}>
          <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Participating lotterys</TYPE.mediumHeader>
        </DataRow>

        <FullPositionCard />
        {!account ? (
          <Card padding="40px">
            <TYPE.body color={theme.text3} textAlign="center">
              Connect to a wallet to view your lotterys.
            </TYPE.body>
          </Card>
        ) : (
          <Card padding="40px">
            <TYPE.body color={theme.text3} textAlign="center">
              <ButtonPrimary padding="8px" borderRadius="8px" width="160px"  >
                Buy Lotterys
              </ButtonPrimary>
            </TYPE.body>

          </Card>
        )}

        <ColumnCenter>
          <BlueCard style={{width:"100%"}}>
            <AutoColumn gap="10px">
              <TYPE.link style={{margin:"0px 0px 20px 0px"}} fontWeight={600} color={'primaryText1'}>
                Latest Winning Numbers
              </TYPE.link>
              <TYPE.link style={{margin:"0px 0px 20px 0px"}} fontWeight={400} color={'white'}>
                <div style={{ textAlign: 'center' }}>
                  <span style={{fontSize:'16px',margin:"0px 12px 0px 0px",fontWeight:'bold',borderRadius:'12px',padding:"10px 10px 7px",background:"radial-gradient(174.47% 188.91% at 1.84% 0%,#FF7AF5 0%,#513162 100%),#edeef2"}}>
                    4
                  </span>
                  <span style={{fontSize:'16px',margin:"0px 12px 0px 0px",fontWeight:'bold',borderRadius:'12px',padding:"10px 10px 7px",background:"radial-gradient(174.47% 188.91% at 1.84% 0%,#FF7AF5 0%,#513162 100%),#edeef2"}}>
                    5
                  </span>
                  <span style={{fontSize:'16px',margin:"0px 12px 0px 0px",fontWeight:'bold',borderRadius:'12px',padding:"10px 10px 7px",background:"radial-gradient(174.47% 188.91% at 1.84% 0%,#FF7AF5 0%,#513162 100%),#edeef2"}}>
                    6
                  </span>
                  <span style={{fontSize:'16px',fontWeight:'bold',borderRadius:'12px',padding:"10px 10px 7px",background:"radial-gradient(174.47% 188.91% at 1.84% 0%,#FF7AF5 0%,#513162 100%),#edeef2"}}>
                    7
                  </span>
                </div>
              </TYPE.link>
              <TYPE.link fontWeight={400} color={'white'}>
                Tickets matching 4 numbers: 0
              </TYPE.link>
              <TYPE.link fontWeight={400} color={'white'}>
                Tickets matching 3 numbers: 0
              </TYPE.link>
              <TYPE.link fontWeight={400} color={'white'}>
                Tickets matching 2 numbers: 0
              </TYPE.link>
            </AutoColumn>
          </BlueCard>
        </ColumnCenter>
        <ColumnCenter>
          <BlueCard style={{width:"100%"}}>
            <AutoColumn gap="10px">
              <TYPE.link fontWeight={600} color={'primaryText1'}>
                How it works.
              </TYPE.link>
              <TYPE.link fontWeight={400} color={'white'}>
                Spend ZENSTOKEN to buy tickets, contributing to the lottery pot. Win prizes if 2, 3, or 4 of your ticket numbers match the winning numbers and their exact order!
              </TYPE.link>
            </AutoColumn>
          </BlueCard>
        </ColumnCenter>

      </AutoColumn>
    </PageWrapper>
  )
}
